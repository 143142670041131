export type DBType = {
  [key: string]: string
}

var DB: DBType = {
  '2024-03-24': 'Hello',
  '2024-03-04': 'Note 1',
  '2024-03-07': 'text text text',
  '2024-03-08': 'random text!',
  '2023-01-10': 'Hellooooo',
}

export type DailyType = {
  date: string
  text: string
}

export type UserData = {
  uid: string,
  displayName: string,
  email: string
}
export const getAllDailies = async (userId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/dates/${userId}`
  )

  const dailies = await response.json()
  DB = dailies
  return dailies
  // return DB
}

export const createOrUpdateDaily = async (userId: string, daily: DailyType) => {
  DB[daily.date] = daily.text
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/dates/${userId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(DB),
    }
  )
  const newDaily = await response.json()
  return newDaily

  // return DB
}

export const createUser = async (userData: UserData) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/users`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }
  )

  if(!response.ok) {
    throw new Error('Network response was not ok')
  }
  return await response.json()
}
