import { useState } from "react";
import TaskIcon from "../icons/task";
import authFirebase from "../services/authFirebase";
import Modal from "./modal/modal";

type HeaderProps = {
    displayName: string | null | undefined
  }

  
export default function Header(props: HeaderProps) {
    const {
        displayName
    } = props
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const modalDetails = {
        title: 'Are you sure you want to sign out?',
        buttonText1: 'Sign out',
        buttonText2: 'Cancel',
        onClickButton1: () => {
            authFirebase.signOut()
        },
        onClickButton2: () => {
            setIsModalOpen(false)
        },

    }
    return (
        <div className="header">
            <div className="header-logo">
                <TaskIcon />
                My Dailies
            </div>
            <div className="user">
                {displayName}
                <img src={process.env.PUBLIC_URL + '/user-example.jpeg'} alt="user" />
                <button onClick={() => setIsModalOpen(true)}>{'Signout'}</button>
                
                <Modal 
                    isOpen={isModalOpen} 
                    onClose={() => setIsModalOpen(false)}
                    modalType={'basic'}
                    modalDetails={modalDetails}
                />
            </div>
        </div>
    )    
}