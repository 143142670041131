import { useCallback, useEffect, useRef, useState } from 'react';
import {DailyType} from '../api';
import LeftArrowIcon from '../icons/leftArrow';
import RightArrowIcon from '../icons/rightArrow';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import TextTools from './textTools';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Daily({daily, onChange, forward, backwards}: {daily: DailyType|undefined, onChange: (daily: DailyType) => void, forward: undefined|(() => void), backwards: undefined|(() => void)}) {
    const [dailyCopy, setDailyCopy] = useState(daily);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    
    useEffect(() => {
        setDailyCopy(daily)
    }, [daily]);
  
    
    const debouncedOnChange = useCallback((newDaily: DailyType) => {
        setDailyCopy(newDaily);
        if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            onChange(newDaily);
        }, 500);
    }, [onChange]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
        };
    }, []);

    if (!dailyCopy) return null;
    return (
        <div className="daily">
            <div className="date" >
                <div className='date-controls'>
                    <div className={`date-control `} onClick={backwards}><LeftArrowIcon  /></div>
                    <div className={`date-control `} onClick={forward}><RightArrowIcon  /></div>
                </div>
                <h1 style={{userSelect: 'none'}}>{dailyCopy.date}</h1>
            </div>
            {/* Incase I need to build my own... use this! */}
            {/* <div role={'textbox'} contentEditable="true">Check</div> */} 
            <div className={'ck-wrap'}>
                <CKEditor
                   config={{
                        placeholder: 'Set your goal for today..'
                    }} 
                    editor={ClassicEditor}
                    onReady={(editor) => editor.focus()} 
                    data={dailyCopy.text}
                    onChange={(event, editor) => {
                        const data = editor.getData();   
                        debouncedOnChange({ ...dailyCopy, text: data });
                    }}
                />
            </div>
        </div>
    )
    
}