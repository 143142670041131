import { useEffect, useState } from 'react'
import Daily from '../components/daily'
import DatePicker from '../components/datePicker'
import Header from '../components/header'
import SideNav from '../components/sideNav'
import { DBType, DailyType, createOrUpdateDaily, getAllDailies } from '../api'

type MainProps = {
  userId: string,
  displayName: string
}

const today = new Date().toISOString().split('T')[0]
function generateDates(): string[] {
  const today = new Date()
  const startDate = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  )
  const endDate = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  )

  const dates: string[] = []
  let currentDate = startDate

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`

    dates.push(formattedDate)
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

const dates = generateDates()
export default function Main({ userId, displayName} : MainProps) {

 
  const [dailies, setDailies] = useState<DBType | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  )
  const [forceScroll, setForceScroll] = useState(false)

  useEffect(() => {
    getAllDailies(userId).then(setDailies)
  }, [])

  useEffect(() => {
    if (dailies && !selectedDate) {
      setSelectedDate(today)
    }
  }, [dailies, selectedDate])

  useEffect(() => {
    if (forceScroll) {
      setForceScroll(false)
    }
  }, [forceScroll])

  const handleEdit = (daily: DailyType) => {
    createOrUpdateDaily(userId, daily).then(setDailies)
  }

  const selectToday = () => {
    setSelectedDate(today)
    if (selectedDate === today) {
      setForceScroll(true)
    }
  }

  function getPreviousDate(dateString: string): string {
    const date = new Date(dateString)
    date.setDate(date.getDate() - 1)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const previousDate = `${year}-${month}-${day}`

    return previousDate
  }

  function getNextDate(dateString: string): string {
    const date = new Date(dateString)
    date.setDate(date.getDate() + 1)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const nextDate = `${year}-${month}-${day}`

    return nextDate
  }

  if (!dailies || !selectedDate) return null

  return (
    <div className='main'>
      <Header displayName={displayName}/>
      <div className='main-content'>
        <SideNav isToday={selectedDate === today} selectToday={selectToday} />
        <DatePicker
          dates={dates}
          selected={selectedDate}
          onSelectDate={setSelectedDate}
          forceScroll={forceScroll}
        />
        <Daily
          daily={{ date: selectedDate, text: dailies[selectedDate] || '' }}
          onChange={handleEdit}
          forward={() => setSelectedDate(getNextDate(selectedDate))}
          backwards={() => setSelectedDate(getPreviousDate(selectedDate))}
        />
      </div>
    </div>
  )
}
