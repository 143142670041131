import CalendarIcon from "../icons/calendar";
import HelpIcon from "../icons/help";
import SearchIcon from "../icons/search";
import SettingsIcon from "../icons/settings";
import TodayIcon from "../icons/today";
import NavLink from "./navLink";

export default function SideNav({selectToday, isToday}: {selectToday: () => void, isToday: boolean}) {
    return (
        <div className="side">
            <div className="side-menu">
                <NavLink onClick={() => {}} icon={<SearchIcon />} text="Search" isDisabled={false} isSelected={false} />
                <NavLink onClick={() => {}} icon={<CalendarIcon />} text="Calendar" isDisabled={false} isSelected={false} />
                <NavLink onClick={selectToday} icon={<TodayIcon />} text="Today" isDisabled={false} isSelected={isToday} />
            </div>
            <div className="side-menu">
            <NavLink onClick={() => {}} icon={<HelpIcon />} text="Help" isDisabled={false} isSelected={false} />
            <NavLink onClick={() => {}} icon={<SettingsIcon />} text="Settings" isDisabled={false} isSelected={false} />
            </div>
        </div>
    )
}