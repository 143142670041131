import styles from'./custombutton.module.css'
import { BUTTON_BUTTON, BUTTON_RESET, BUTTON_SUBMIT } from '../../constants/button-types'

type CustomButtonProps = {
    className?: string,
    buttonColor?: string,
    children: React.ReactNode,
    type?: typeof BUTTON_SUBMIT | typeof BUTTON_RESET | typeof BUTTON_BUTTON,
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

export default function CustomButton(props: CustomButtonProps) {

    const {
        children = '',
        className = '',
        type = 'button',
        buttonColor = '',
        onClick = () => {}
    } = props
    return (
        <button 
            onClick={onClick}
            className={`${styles['btn-container']} ${className} ${buttonColor ? styles['light'] : ''}`}
            type={type}
        >
            {children}
        </button>
    )
} 