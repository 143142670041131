import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  UserCredential,
  User,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
} from 'firebase/auth'
import { auth } from '../firebaseConfig'
import { createUser, UserData } from '../api'

const googleProvider = new GoogleAuthProvider()

const authFirebase = {
  signUp: async (email: string, password: string): Promise<User> => {
    try {
      const userCredential: UserCredential =
        await createUserWithEmailAndPassword(auth, email, password)
      const user: User = userCredential.user
      await sendEmailVerification(user)
      const createUserData: UserData = {
        uid: user.uid as string,
        displayName: '',
        email: user?.email as string
      }
      await createUser(createUserData)
      return user
    } catch (error) {
      throw error
    }
  },
  login: async (email: string, password: string): Promise<User> => {
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      return userCredential.user
    } catch (error) {
      throw error
    }
  },
  signOut: async (): Promise<void> => {
    try {
      await signOut(auth)
    } catch (error) {
      console.log(error)
    }
  },
  sendVerificationEmail: async (user: User): Promise<void> => {
    try {
      await sendEmailVerification(user)
    } catch (error) {
      console.log(error)
    }
  },
  sendPasswordResetEmail: async (email: string): Promise<void> => {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      console.log('Error sending password reset email:', error)
      throw error
    }
  },
  signInWithGoogle: async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider)
      // TODO: Call server to create new user only if returned success from "res"
      const isNewUser = getAdditionalUserInfo(res)
      const createUserData: UserData = {
        uid: isNewUser?.profile?.id as string,
        displayName: isNewUser?.profile?.name as string,
        email: isNewUser?.profile?.email as string
      }
      if(isNewUser?.isNewUser) {
        await createUser(createUserData)
      }
    } catch (error) {
      console.log('error: ', error)
    }
  },
}

export default authFirebase
