import { useRef, useEffect } from "react";

export default function DatePicker({dates, onSelectDate, selected, forceScroll}: {dates: string[], onSelectDate: (date: string) => void, selected:string|undefined, forceScroll: boolean}) {
    const today = new Date().toISOString().split('T')[0];
  
    const selectedRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selected]);

    useEffect(() => {
        if (forceScroll && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [forceScroll])

    return (
        <div className="date-picker-container">
            <div className="date-picker">
                <div className="date-picker-content" >
                    {
                        dates.map((date: string) => {
                            return (
                                <div ref={date === selected ? selectedRef : null} className={`date-picker-item ${date === selected ? 'selected' : ''} ${date === today ? 'today' : ''}`} key={date} onClick={() => onSelectDate(date)}>
                                    {date}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}